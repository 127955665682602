@font-face {
    font-family: "DIN";
    src: local("DIN"), url("./assets/fonts/din.tff") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Bree";
    src: local("Bree"), url("./assets/fonts/bree.tff") format("truetype");
    font-weight: normal;
}

body {
    font-family: "DIN" !important;
}

code {
    font-family: "Bree";
}

.notion-link {
    z-index: 0 !important;
}

body {
    height: 100vh;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* transiton du changement de theme (dark mode to light mode) */

body * {
    transition: background-color 0.5s linear, color 0.5s linear;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.login {
    background-size: 100vw;
}

.sketch-picker {
    background-color: inherit !important;
    box-shadow: none !important;
}

.sketch-picker span {
    color: inherit !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-adornedStart {
    color: inherit !important;
}

.spinning {
    -webkit-animation: spin 0.750s linear infinite;
    animation: spin 0.750s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (min-device-aspect-ratio: 16/9) {
    main {
        max-width: calc(100% - 240px);
        height: 100vh;
    }
}

@media screen and (min-device-aspect-ratio: 16/10) {
    main {
        max-width: calc(100% - 240px);
        height: 100vh;
    }
}

@media screen and (max-width: 700px) {
    main {
        max-width: 100%;
        height: 100vh;
    }
}